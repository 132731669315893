import React, { Component } from 'react';
import OrdVikunnar from './OrdVikunnar';
import PageView from './PageView';

class Frontpage extends Component {
	render() {
		return (
			<React.Fragment>

				<div className="row">
					<div className="col-md-9">

						<PageView staticLocation="intro" />

					</div>

					<div className="col-md-3">
						<OrdVikunnar />
					</div>

				</div>

			</React.Fragment>
		);
	}
}

export default Frontpage;
