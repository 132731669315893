import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import ContactForm from './ContactForm';

class ContactFormWrapper extends Component {
	render() {
		return (
			<div className={'card content-container mt-4 manual-init'}>
				 <div className="page-content card-body">
						<h2>Hafa samband</h2>
						<br/>
						<ContactForm formId={'KATA'} requirePhoneNumber />
				</div>
			</div>
		);
	}
}

export default ContactFormWrapper;
