import React, { Component } from 'react';
import { Link } from "react-router-dom";

import TermView from './TermView';
import CollapsibleCard from './CollapsibleCard';
import TermIndexList from './TermIndexList';

import config from './../config';
import islexHelper from './../islexHelper';
import SimilarTerms from './SimilarTerms';
import { Helmet } from 'react-helmet';
import _ from 'underscore';


class TermViewContainer extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/es/fletta/';

		this.state = {
			found: true
		};
	}

	listToTree(list) {
		let map = {};
		let item;
		let roots = [];

		let i;
		for (i = 0; i < list.length; i += 1) {
			map[list[i].itid] = i; // initialize the map
			list[i].pitems = []; // initialize the children
		}

		for (i = 0; i < list.length; i += 1) {
			item = list[i];
			if (item.paritem != -1) {
				// if you have dangling branches check that map[item.paritem] exists
				if (list[map[item.paritem]]) {
					list[map[item.paritem]].pitems.push(item);
				}
			} else {
				roots.push(item);
			}
		}

		return roots;
	}

	formatResults(data) {
		return this.listToTree(data);
	}

	componentDidMount() {
		if (this.props.match && this.props.match.params.entry_id) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Athuga hvort breytur hafi breyst og hvort component eigi að uppfærast

		if (this.props.match && this.props.match.params.entry_id &&
			(
				prevProps.match.params.entry_id != this.props.match.params.entry_id ||
				this.props.match.params.lang != prevProps.match.params.lang
			)
		) {
			this.fetchData();
		}
	}

	fetchData() {
		// Sæki gögn til APA

		fetch(this.url+this.props.match.params.entry_id+'?lang=IS')
			.then(function(response) {
				if (!response.ok) {
					this.setState({
						found: false
					})

					return false;
				}
				else {
					return response.json();
				}
			}.bind(this))
			.then(function(json) {
				if (json) {
					let dataItem = json;

					let explanationStr = _.findWhere(dataItem.items, {teg: 'SKÝRING'});
					explanationStr = explanationStr ? explanationStr.texti : null;

					if (dataItem.items) {
						dataItem.items = this.formatResults(dataItem.items);
					}

					this.setState({
						found: dataItem.found === false ? false : true,
						data: dataItem,
						explanationStr: explanationStr
					}, function() {
						if (this.props.history.location.search.indexOf('?itid=') > -1) {
							this.goToItem(this.props.history.location.search.replace('?itid=', ''));
						}
					}.bind(this));
				}
			}.bind(this));

		window.scrollTo(0, 0);
	}

	goToItem(itid) {
		setTimeout(function() {
			let element = document.querySelector('[data-itid="'+itid+'"]');

			if (element) {
				element.classList.add('item-highlight');
				window.scrollTo(0, element.offsetTop);
			}
		}, 0);
	}

	render() {
		let isEmbedded = this.props.location.pathname.indexOf('ord_embed') > -1

		let dataItem = this.state.data;

		if (!this.state.found) {
			return (
				<div className="row">
					<div className="col-12 col-sm-5 col-md-3">

					</div>

					<div className="col-12 col-sm-7 col-md-9">
						<ul className="list-group mb-4 dictionary-list">
							<li className="list-group-item text-center">
								<div className="h5 mt-3 mb-4">Þessi færsla finnst ekki.</div>
							</li>
						</ul>
					</div>
				</div>
			);
		}
		else if (dataItem && this.state.found) {
			return (
				<div className="row">

					<Helmet>
						<meta property="og:type"
							content="article"
						/>
						<meta property="og:title"
							content={dataItem.fletta+' '+islexHelper.formatOflStr(dataItem.ofl)+' | Íslensk nútímamálsorðabók'}
						/>
						{
							this.state.explanationStr && <meta property="og:description"
								content={this.state.explanationStr}
							/>
	
						}
					</Helmet>


					<div className={'col-12 '+(!isEmbedded ? 'col-sm-7 col-md-9' : '')+' d-flex flex-column'}>
						{
							this.state.data &&
							<React.Fragment>

								<div className={'dictionary-entry card flex-grow-1'}>
									<div className={'card-body'}>

										<TermView data={dataItem} lang={this.props.match.params.lang} />

										{
											['adj', 'n', 'adv', 'v', 'forl'].indexOf(dataItem.ofl.split(' ')[0]) > -1 &&
											<SimilarTerms fletta={dataItem.fletta} ofl={dataItem.ofl} />
										}

									</div>
								</div>
							</React.Fragment>
						}
					</div>

					{
						!isEmbedded && <div className="col-12 col-sm-5 col-md-3">
							<div className="index-column">

								{/*<Link className="btn btn-info btn-block mb-2" to="/">&lt; {window.l('Til baka á forsíðu')}</Link>*/}

								<TermIndexList flid={this.props.match.params.entry_id} lang={this.props.match.params.lang} />

							</div>

						</div>
					}

				</div>
			);
		}
		else {
			return null;
		}
	}
}

export default TermViewContainer;
