import React, { Component } from 'react';

export default function SiteFooter(props) {
	return <div className="site-footer">
		<div className="logos">
			<a href="http://www.dictionaryportal.eu/" target="_blank" title="European Dictionary Portal"><img src="/img/logo-dictionaryportal.gif"/></a>
		</div>
		<div className="footer-text">© Stofnun Árna Magnússonar í íslenskum fræðum, Árnagarði við Suðurgötu, 101 Reykjavík</div>
	</div>;
}
