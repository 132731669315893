export default {
	pageSize: 50,

	siteTitle: 'Íslensk nútímamálsorðabók',
	site: 'kata',

	//apiRoot: process.env.REACT_APP_API_ROOT,
	apiRoot: '//islenskordabok.arnastofnun.is/django',
	//apiRoot: 'http://130.208.108.18:8000',

	islexRoot: '//islex.arnastofnun.is/django/',
	//islexRoot: 'http://130.208.108.18:8000',

	audioUrl: '//islenskordabok.arnastofnun.is/islex-files/audio/',
	imageUrl: '//kata.arnastofnun.is/myndir/',
	//imageUrl: '//islenskordabok.arnastofnun.is/islex-files/myndir/',

	gaTrackingId: 'G-VR2BYPT3ES'
}
