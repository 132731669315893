import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import islexHelper from './../islexHelper';
import _ from 'underscore';
import config from './../config';
import { Helmet } from 'react-helmet';

class TermItemView extends Component {
	renderAudioEl(dataItem) {
		let audioFormats = dataItem.texti.split('\t')[0].split(',');

		let audioUrl = config.audioUrl+Math.floor(dataItem.flid/1000)+'/'+dataItem.itid;

		return <div className="dict-item dict-framb">
			<audio onPlay={function() {
					console.log('Playing');
				}}
				onError={function(errorCode, description) {
					console.log(errorCode);
					console.log(description);
				}}
				ref="audioControl"
				controls
			>
				{
					audioFormats.map(function(format) {
						return <source key={format} src={audioUrl+'.'+format} type={'audio/'+format}/>
					})
				}
			</audio>
			<a onClick={
				function() {
					//this.refs.audioControl.load();
					//this.refs.audioControl.play()
					this.refs.audioControl.load();
					this.refs.audioControl.play()
				}.bind(this)
			}>{window.l('Framburður')}</a>
		</div>;
	}

	getImageUrl(dataItem) {
		function lpad(value, padding) {
			let zeroes = new Array(padding+1).join("0");
			return (zeroes + value).slice(-padding);
		}

		let imageUrl = config.imageUrl+lpad(Math.floor(dataItem.itid/10000), 5)+'/'+dataItem.texti;

		return imageUrl;
	}

	render() {
		let dataItem = this.props.item;
		let type = dataItem.teg;

		let el;

		let lastTeg = '';

		let subItems = dataItem.pitems && dataItem.pitems.length > 0 ?
			dataItem.pitems.map(function(pitem, index) {
				let termItemView;

				if (this.props.lang) {
					if (
						(
							pitem.teg.toLowerCase().startsWith(this.props.lang.toLowerCase()) ||
							pitem.teg.toLowerCase().endsWith(this.props.lang.toLowerCase()) ||
							pitem.teg.startsWith('NOT-') ||
							pitem.teg == 'OSTÆÐA' ||
							pitem.teg == 'DÆMI' ||
							pitem.teg == 'Z-PERSÓNA' ||
							pitem.teg == 'LIÐUR' ||
							pitem.teg == 'VÍSUN' ||
							pitem.teg == 'MYND' ||
							pitem.teg == 'SOSTÆÐA' ||
							pitem.teg == 'OSAMB' ||
							pitem.teg == 'SOHAUS' ||
							pitem.teg == 'TILFØJELSE' ||
							pitem.teg == 'FRUMLAG'
						) && !pitem.teg.startsWith('MORFO-')
					) {
						termItemView = <TermItemView key={pitem.itid} lastTeg={lastTeg} lang={this.props.lang} item={pitem} />;
					}
				}
				else {
					termItemView = <TermItemView key={pitem.itid} lastTeg={lastTeg} item={pitem} />;
				}

				lastTeg = pitem.teg;

				return termItemView;
			}.bind(this)) : [];

		if (type == 'STRIK') {
			el = <hr data-type={type} data-itid={dataItem.itid} />;
		}
		else if (type == 'FRAMB') {
			el = this.renderAudioEl(dataItem)
		}
		else if (type == 'BEYGING') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-link mb-3"><a target="_blank" href={dataItem.texti.split('	')[0].split(' ')[0]}>{window.l('Beyging')}</a></div>;
		}
		else if (type == 'FORM') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-fallstj">{dataItem.texti}</div>;
		}
		else if (type == 'FRUMLAG') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-fallstj dict-frumlag">{window.l('Frumlag')}: {dataItem.texti}</div>;
		}
		else if (type == 'FALLSTJ') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-fallstj">{dataItem.texti}</div>;
		}
		else if (type == 'FALLSTJ-FS') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-fallstj"><strong>{dataItem.texti}</strong></div>;
		}
		else if (type == 'MYND') {
			let linkUrl;
			let linkText;

			if (dataItem.pitems && dataItem.pitems.length) {
				try {
					let linkFrags = dataItem.pitems[0].texti.match(/^(\S+)\s(.*)/).slice(1)
					linkUrl = linkFrags[0]
					linkText = linkFrags[1];
				}
				catch (e) {
					linkUrl = dataItem.pitems[0].texti;
					linkText = '';
				}
			}

			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-image">
				<img src={this.getImageUrl(dataItem)} />
				{
					dataItem.pitems && dataItem.pitems.length > 0 &&
					<div className="image-caption">
						{
							dataItem.pitems[0].teg == 'WWW' &&
							<a href={linkUrl}>{linkText}</a>
						}
						{
							dataItem.pitems[0].teg != 'WWW' &&
							<a>{dataItem.pitems[0].texti}</a>
						}
					</div>
				}
			</div>;
		}
		else if (type == 'ISL-TEXTI') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-merking">{dataItem.texti}</div>;
		}
		else if (type == 'SKÝRING') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item">{dataItem.texti}</div>;
		}
		else if (type == 'Z-LO') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item">({dataItem.texti})</div>;
		}
		else if (type == 'Z-PERSÓNA') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-merking"><img style={{maxWidth: 32}} src="/img/icon-person.png"/></div>;
		}
		else if (type == 'Z-HESTUR') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-merking"><img style={{maxWidth: 32}} src="/img/icon-hestur.png"/></div>;
		}
		else if (type == 'LIÐUR') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-section">
				<div className="dict-section-num"> {dataItem.texti}</div>
				{
					subItems
				}
			</div>;
		}
		else if (type == 'WWW') {
			let linkUrl = dataItem.texti.split(' ')[0];
			let linkText = dataItem.texti.split(' ')[1];
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item"><a href={linkUrl}>{linkText}</a></div>;
		}
		else if (type == 'HLUTAR') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item"><span className="dict-text-upper">Orðhlutar:</span> {dataItem.texti}</div>;
		}
		else if (type == 'NOTKUN') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-notkun"><em>{dataItem.texti}</em></div>;
		}
		else if (type == 'LATINA') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item"><em>({dataItem.texti})</em></div>;
		}
		else if (type == 'SVIÐ') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item"><em>{dataItem.texti}</em></div>;
		}
		else if (type.endsWith('-kateg')) {
			el = subItems;
		}
		else if (type.endsWith('-skýr')) {
			let displayLang;

			// Athuga hvort fáni eigi að birtast
			if (type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)) {
				displayLang = type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)[0].replace('-', '');
			}
			// Leiðrétta norska fánann
			if (displayLang == 'NO' && type.startsWith('NO-N')) {
				displayLang = 'NO-N';
			}
			if (displayLang == 'NO' && type.startsWith('NO-B')) {
				displayLang = 'NO-B';
			}

			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-skyring">
				{
					displayLang &&
					<img className="button-flag" title={islexHelper.tungumal[displayLang].name} src={'/img/flags/'+displayLang+'.png'} />
				}
				<span className="skyring">{dataItem.texti}</span>

				<div className="clear-fix" />
			</div>;
		}
		else if (type == 'DÆMI') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-daemi">
				<div className="daemi"><span className="dict-text-upper">Dæmi:</span> {dataItem.texti}</div>
				{
					dataItem.pitems && dataItem.pitems.length > 0 &&
					dataItem.pitems.map(function(daemiTranslation) {
						return <div key={daemiTranslation.itid} className="daemi-translation">
							{daemiTranslation.texti}
						</div>
					})
				}
			</div>;
		}
		else if (type.startsWith('NOT-') || type == 'TILFØJELSE') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-notkunarsvid dict-text-light">({dataItem.texti})</div>;
		}
		else if (type == 'SOSTÆÐA' || type == 'OSTÆÐA' || type == 'OSAMB' || type == 'SOHAUS') {
			let displayLang;

			// Athuga hvort fáni eigi að birtast
			if (type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)) {
				displayLang = type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)[0].replace('-', '');
			}
			// Leiðrétta norska fánann
			if (displayLang == 'NO' && type.startsWith('NO-N')) {
				displayLang = 'NO-N';
			}
			if (displayLang == 'NO' && type.startsWith('NO-B')) {
				displayLang = 'NO-B';
			}

			el = <div data-type={type} data-itid={dataItem.itid} className={'dict-item '+(type == 'SOSTÆÐA' || type == 'OSTÆÐA' || type == 'OSAMB' ? 'dict-osamband' : 'dict-sohaus')+(this.props.firstLevel ? ' first-level' : '')}>
				{
					displayLang &&
					<img className="button-flag" title={islexHelper.tungumal[displayLang].name} src={'/img/flags/'+displayLang+'.png'} />
				}
				<div className={(type == 'SOSTÆÐA' || type == 'OSTÆÐA' || type == 'OSAMB' ? 'osamband' : 'sohaus')}>{dataItem.texti}</div>
				{
					subItems
				}
			</div>;
		}
		else if (type.startsWith('KOM-')) {
			el = <span data-type={type} data-itid={dataItem.itid} className="inline-skyring dict-text-light">({dataItem.texti})</span>;
		}
		/*
		else if (type == 'SOHAUS') {
			el = <div><strong>SOHAUS</strong></div>;
		}
		*/
		else if (type.indexOf('-jafn') > -1 || type.indexOf('-þýð') > -1) {
			let displayLang;

			// Athuga hvort fáni eigi að birtast
			if (type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)) {
				displayLang = type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)[0].replace('-', '');
			}
			// Leiðrétta norska fánann
			if (displayLang == 'NO' && type.startsWith('NO-N')) {
				displayLang = 'NO-N';
			}
			if (displayLang == 'NO' && type.startsWith('NO-B')) {
				displayLang = 'NO-B';
			}

			let TagName = dataItem.skil == '/' || dataItem.skil == ';/' || !this.props.lang ? 'div' : 'span';

			let titleText;
			if (dataItem.pitems && dataItem.pitems.length == 1 && dataItem.pitems[0].teg.startsWith('MORFO-')) {
				titleText = dataItem.pitems[0].texti;
			}

			el = <React.Fragment>
				<TagName data-type={type} data-itid={dataItem.itid} className={'dict-item dict-jafn'+(this.props.firstLevel ? ' first-level' : '')}>
					{
						displayLang &&
						<img className="button-flag" title={islexHelper.tungumal[displayLang].name} src={'/img/flags/'+displayLang+'.png'} />
					}

					<span title={titleText || null}>{dataItem.texti}
						{
							titleText &&
							<span className="ml-2 dict-text-lighter">{titleText}</span>
						}
					</span>

					{
						/*
						dataItem.pitems.length == 1 && dataItem.pitems[0].teg.startsWith('MORFO-') &&
						<div className="dict-morfo">{dataItem.pitems[0].texti}</div>
						*/
					}

					{
						subItems
					}
					{
						(dataItem.skil == '/' || dataItem.skil == ';/') &&
						<div className="clear-fix" />
					}
				</TagName>
				{
					//(type == this.props.lastTeg) &&
					//<div className="clearfix" />
				}
			</React.Fragment>;
		}
		else if (type == 'VÍSUN') {
			let parts = dataItem.texti.match(/([0-9]+)? ?([^,]+), ([^:]+):?([^\t]+)?(, )?([^\t]+)?\t?(.+)?/);

			try {

				let linkText = parts[4] || parts[2];
				let linkEl;

				console.log(parts)

				if (linkText.indexOf(', ') > -1) {
					linkEl = <React.Fragment>{(parts[7] ? parts[7]+' ' : '')+linkText.split(', ')[0]+(parts[1] && !parts[4] ? ' '+parts[1] : '')}, {islexHelper.formatOfl(linkText.split(', ')[1])}</React.Fragment>;
				}
				else {
					linkEl = (parts[7] ? parts[7]+' ' : '')+linkText+(parts[1] && !parts[4] ? ' '+parts[1] : '');
				}

				el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-visun dict-link mt-3">
					<Link to={'/leit/"'+parts[2]+'"'+(parts[3] ? '/ofl/'+parts[3].replace('/', ' ').split(' ')[0] : '')+(parts[1] ? '/rnum/'+parts[1] : '')+'?synafyrstu'}>
						{linkEl}
					</Link>
				</div>;
			}
			catch {
				el = <div>{dataItem.texti}</div>
			}
		}
		else if (type == 'MÁLF-BANKI') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item">
				<hr/>
				<p><span className="dict-text-upper">Úr málfarsbankanum:</span><br/>
					<span dangerouslySetInnerHTML={{__html: dataItem.texti}} />
				</p>
			</div>;
		}
		else if (type == 'MÁLSHÁ') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item">
				<hr/>
				<p><span className="dict-text-upper">Málsháttur:</span><br/>
					<em>{dataItem.texti}</em>
				</p>
			</div>;
		}
		else if (type == 'BIRT-ATH') {
			el = <div data-type={type} data-itid={dataItem.itid} className="dict-item dict-ath">
				<hr/>
				<p>{dataItem.texti}</p>
			</div>;
		}
		else {
			let displayLang;

			// Athuga hvort fáni eigi að birtast
			if (type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)) {
				displayLang = type.match(/^[A-Z]{2}-|-[A-Z]{2}$/g)[0].replace('-', '');
			}
			// Leiðrétta norska fánann
			if (displayLang == 'NO' && type.startsWith('NO-N')) {
				displayLang = 'NO-N';
			}
			if (displayLang == 'NO' && type.startsWith('NO-B')) {
				displayLang = 'NO-B';
			}

			el = <div data-type={type} data-itid={dataItem.itid} style={{border: '1px solid #999'}}>
				<div><strong>VANTAR BIRTINGU: {type}</strong></div>

				<p>
					{
						displayLang &&
						<img className="button-flag" title={islexHelper.tungumal[displayLang].name} src={'/img/flags/'+displayLang+'.png'} />
					}

					{type}: {dataItem.texti}
				</p>
				{
					subItems
				}
			</div>;

			el = null;
		}

		return <React.Fragment>
			{
				this.props.extraHeading &&
				<div><span className="dict-text-upper">{this.props.extraHeading}</span></div>
			}
			{el}
		</React.Fragment>;
	}
}

export default withRouter(TermItemView);
