import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Redirect, Route, Link, Switch } from "react-router-dom";
import _ from 'underscore';

import SearchBox from './components/SearchBox';
import Frontpage from './components/Frontpage';
import SearchResultsList from './components/SearchResultsList';

import PageMenu from './components/PageMenu';
import TermViewContainer from './components/TermViewContainer';
import StickyWatcher from './components/StickyWatcher';
import SiteFooter from './components/SiteFooter';
import PageView from './components/PageView';
import ContactFormWrapper from './components/ContactFormWrapper';
import GoogleAnalytics from './components/GoogleAnalytics';

import EventBus from 'eventbusjs';

import config from "./config";

import logo from './img/logo-single.png';

class App extends Component {
	constructor(props) {
		super(props);

		window.eventBus = EventBus;

		this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);

		this.fetchOflTranslations();

		this.state = {
			searchParams: {},
			menuOpen: false
		};
	}

	componentDidMount() {
		this.refs.router.history.listen(function() {
			this.setState({
				menuOpen: false
			});
		}.bind(this));

		document.addEventListener('mousedown', function(event) {
			if (!this.state.menuOpen) {
				return;
			}

			if (this.refs.menuButton.contains(event.target)) {
				event.stopPropagation();

				return;
			}

			if (this.refs.menuContent && !this.refs.menuContent.contains(event.target)) {
				this.setState({
					menuOpen: false
				})
			}
		}.bind(this));

		window.eventBus.addEventListener('AppMenu.close', function() {
			this.setState({
				menuOpen: false
			});
		}.bind(this));
	}

	fetchOflTranslations() {
		window.islexData = {};

		// Sæki nöfn og kóða allra tungumála
		fetch(config.apiRoot+'/api/ofltrans?limit=1000')
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				window.islexData['oflTrans'] = json.results;

				this.setState({
					oflTrans: json.results
				})
			}.bind(this));
	}

	menuButtonClickHandler(event) {
		event.stopPropagation();
		if (!this.state.menuOpen) {
			this.refs.menuContent.scrollTop = 0;
		}

		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	render() {
		return (
			<BrowserRouter ref="router">
				<Switch>
				<Route exact strict path="(/ord_embed/)?:entry_id*" render={(routeProps) => (
					<React.Fragment>
						<TermViewContainer {...routeProps} />
					</React.Fragment>
				)} />
				<Route path="*">
					<React.Fragment>
						<div className="app-wrapper">
							<div className="header">

								<div className="container">

									<div className="logo-wrapper">
										<div className="logo">
											<img src={logo} className="mr-4" />
										</div>
										
										<div className="text">
											<div>Íslensk nútímamálsorðabók</div>
										</div>

									</div>

									<PageMenu className="hidden-mobile" />

								</div>
							
							</div>

							<StickyWatcher tagName="div" className="search-container" stickyClassName="sticky">

								<div className="container">
									<button className="btn-menu" ref="menuButton" onClick={this.menuButtonClickHandler}>
										<div className="hamburger-icon black">
											<div className="icon-middle" />
											<div className="icon-before-after" />
										</div>

									</button>

									<SearchBox searchString={this.state.searchParams.searchString || ''} />

								</div>

							</StickyWatcher>

							<div className="container pb-4 ">

								<Switch>
									<Route path="/" component={Frontpage} exact={true} />
									<Route exact path="/islob" render={(routeProps) => {
										let queryObj = _.object(_.compact(_.map(routeProps.location.search.slice(1).split('&'), function(item) {  if (item) return item.split('='); })));
										if (queryObj.ord) {
											return <Redirect to={'/ord/'+queryObj.ord} />
										}
										else {
											return <Redirect to={'/'} />
										}
									}} />
									<Route exact strict path="(/leit/)?:searchString*(/fletta/)?:fletta*(/ofl/)?:ofl*(/rnum/)?:rnum*(/leitarsvid/)?:leitarsvid*(/page/)?:page*" render={(routeProps) => (
										<SearchResultsList onSearch={searchParams => this.setState({searchParams: searchParams})} {...routeProps} />
									)} />
									<Route exact strict path="(/ord/)?:entry_id*" render={(routeProps) => (
										<React.Fragment>
											<TermViewContainer {...routeProps} />
										</React.Fragment>
									)} />
									<Route exact path="/hafa-samband" render={(routeProps) => (
										<ContactFormWrapper />
									)} />
									<Route exact path="/*" render={(routeProps) => (
										<PageView />
									)} />

								</Switch>

							</div>

						</div>

						<SiteFooter />

						<div className={'app-menu'+(this.state.menuOpen ? ' open' : '')} ref="menuContent">
							<div className="container">
		
								<button className="btn btn-link float-right" onClick={
									function() {
										this.setState({
											menuOpen: false
										});
									}.bind(this)}>
									<div className="hamburger-icon black open">
										<div className="icon-middle" />
										<div className="icon-before-after" />
									</div>
								</button>
		
								<h5 className="mb-4 pt-2">ISLEX</h5>

								<PageMenu className="hidden-mobile-up" />
		
							</div>
						</div>

						<GoogleAnalytics />

					</React.Fragment>
				</Route>
				</Switch>
			</BrowserRouter>
		);
	}
}

export default App;
